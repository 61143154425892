const defaultConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  };

const apiSettings = {
    fetchArtworks: async ( data ) => {
        var dataArray = [];
        const {CarouselItems} = data;
        for (var i = 0; i < CarouselItems.length; i++) {
           console.log(CarouselItems[i])
            dataArray.push(CarouselItems[i])
        }

        return dataArray;
    }
};

export default apiSettings;