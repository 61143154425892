import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --background: linear-gradient( 90deg,
    #E8E8FF,
     #FFF0FA 25%,
     #FFFBF5 47%,
     #FFFBF5 56%,
     #FFF2FB 65%,
     #F5F5FF 77%
     );
        --black: #000;
        --defaultFont: 'Raleway', sans-serif;
        --cardWidth: 350px;
        --cardHeight: 350px;
        --fontSmall: 11px;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --buttonFontSize: 11px;
        --buttonHeight: 56px;
        --purpleGrey: #696980;
        --defaultInputHeight: 49px;
    }

    * {
        box-sizing: border-box;
        font-family: 'Raleway', sans-serif;
    }

    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--black);
    }
    html{
        margin: 0;
            font-size: 15px;
            font-family: 'Colombia';
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;