import styled from "styled-components";


export const OuterWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 600px;
    /*transform: scale3d(0.5, 0.5, 0.5);*/
    align-items: center;
    justify-content: center;
`;


export const Container = styled.div`
    width: 350px;
    height: 350px;
    position: relative;
    
    perspective: 1000px;
    /* transform: ${ props => props.initialAnimation ? 'scale(0.5)' : 'scale(1)'}; */
`;

export const CarouselContainer = styled.div`
/* transition: transform 1s; */
height: 100%;
width: 100%;
position: absolute;
transform-style: preserve-3d;
/* transform: ${ props => props.initialAnimation ? 'rotateX(90deg)' : ''}; */

/* transition: ${ props => props.initialAnimation ? '4s' : '1s'};; */
`;

export const ItemDetailsContainer = styled.div`
position: absolute;
height: 100%;
width: 100%;
z-index: 100;
color: #ffffff;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family: 'Playfair Display';
    font-size: 26px;
    border-radius: 13px;
    font-weight: 100;
    text-transform: uppercase;
    width: 538px;
height: 538px;
background: ${ props => props.showContent ? 'rgba(0,0,0,.90)' : 'transparent' };

`;

export const ItemDetailsContent = styled.div`

visibility: ${ props => props.showContent ? 'visible' : 'hidden' };
`;

export const PlaceBid = styled.div`
    position: absolute;
    right: 20px;
    font-size: var(--fontSmall);
    font-weight: 500;
    bottom: 20px;
    letter-spacing: 0.04rem;
    cursor: pointer;
`;

export const Price = styled.div`
text-transform: uppercase;
text-align: center;
font-family: 'Playfair Display';
font-size: 42px;
div{
    font-family: inherit;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.05rem;
}

`;


export const AuctionTimer = styled.div`
    left: 20px;
    bottom: 20px;
    text-transform: none;
    font-size: 12px;
    position: absolute;
    font-weight: 500;
    display: flex;

    img{
        height: 1rem;
        width: 1rem;
    }
`;

export const OuterMostWrapper = styled.div`
width: 100%;
height: 600px;
position: absolute;
user-select: none;
display: flex;
overflow: hidden;
justify-content: space-around;
align-items: center;
/* transform: scale(0.5); */
`;

export const HeroContainer = styled.div`
    height: 100%;
    height: 700px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    .Cache {
    display: flex;
    top: 150px;
    position: absolute;
    width: 1100px;
    line-height: 1;
    justify-content: space-between;
    font-size: 100px;
    text-transform: uppercase;
    transform: scale(0.5);
  }

  .Bx {
    display: flex;
    position: absolute;
    width: 1100px;
    line-height: 0.5;
    justify-content: space-between;
    text-transform: uppercase;
    
    div {
    font-size: 350px;
    
    
    z-index: 1;
    position: relative;
  }
  }


    `;

export const Artist = styled.div`
text-align: center;
font-size: var(--fontSmall);
font-weight: 600;
`;

export const Title = styled.div`
text-align: center;
font-size: 26px;
font-weight: 100;
`;

export const Intro = styled.div`
text-transform: uppercase;



`;

export const ArtworkInfo = styled.div`
position: absolute;
bottom: 50px;
`;