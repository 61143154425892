import styled from "styled-components";




export const Wrapper = styled.div`
transition: transform .6s linear;
    overflow: hidden;
    display: flex;
    position: absolute;
    background: #000;
    width: var(--cardWidth);
    height: var(--cardHeight);
    line-height: 1rem;
    font-size: 5em;
    text-align: center;
    color: #FFF;
    opacity: 0.95;
    border-radius: 10px;
    transform: ${ props => props.index !== undefined ? `rotateY(${props.index * 60 + props.angle}deg)  translateZ(350px)` : `rotateX(0deg)`};
    

`;

export const Image = styled.img`
    min-width: var(--cardWidth);
    min-height: var(--cardHeight);
    object-fit: cover;
    transition: opacity 1s;
    position: relative;
    opacity: ${props =>props.opacity};
    :hover{
        opacity: 0.5;
    }
`;

export const Video = styled.video`
    min-width: var(--cardWidth);
    min-height: var(--cardHeight);
    transition: opacity 1s;
    object-fit: cover;
    opacity: ${props =>props.opacity};
`;

export const ItemInfo = styled.div`
    width: var(--cardWidth);
    height: var(--cardWidth);
    position: absolute;
    background: #000;
    opacity: 1;
    display: flex;
    font-weight: 100;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Playfair Display';
    font-size: 26px;
`;

export const Price = styled.div`
   
    height: min-content;
    display: flex;
    flex-direction: column;
    position: relative;
    text-transform: uppercase;


`;

export const Text = styled.div`
font-size: 12px;`;

export const PlaceBid = styled.div`
    font-size: var(--fontMicro);
`;

export const AuctionTimer = styled.div`
font-size: var(--fontMicro);
`;