import React, { useState, useRef, useEffect } from 'react'

import { Price, PlaceBid, AuctionTimer, ArtworkInfo, Container, OuterWrapper, CarouselContainer, OuterMostWrapper, HeroContainer, Artist, Title, Intro, ItemDetailsContainer, ItemDetailsContent } from './Carousel.styles'

import CarouselItem from '../CarouselItem';
import CarouselButton from '../CarouselButton';

import * as data from '../../carouselItems.json';

import anime from "animejs";

import Hourglass from "../../images/hourglass.svg"

// Hook
import { useHomeFetch } from '../../hooks/useHomeFetch';


const ItemDetails = ({price, showContent, showItemDetails, remainingTime}) => (
    <ItemDetailsContainer showContent={showContent} onMouseOver={()=>showItemDetails(true)} onMouseOut={()=>showItemDetails(false)}>
        <ItemDetailsContent showContent={showContent}>
        <Price>{price}
            <div>Current bid</div>
        </Price>
        <PlaceBid>Place Bid</PlaceBid>
        <AuctionTimer><img src={Hourglass} />{remainingTime}
        </AuctionTimer>
        </ItemDetailsContent>
    </ItemDetailsContainer>
)

const CacheBoxTitle = () => {
    const title = "Cache Bx";
    const [ section1, section2 ] = title.split(' ');
    
    
    return (
        <>
        {
            title.split(' ').map((word, index)=> (
                <div className={word} key={index}>
                    {
                        word.split('').map( (letter, index) => (
                            <div
                            key={index}>{letter}</div>
                        ))
                    }
                </div>

            ))
        }
        </>
    )

}

const TitleFirst = ({title}) => {
   // const title = "Cache";
    return (
      
                <div className={title} >
                    {
                        title.split('').map( (letter, index) => (
                            <div
                            key={index}>{letter}</div>
                        ))
                    }
                </div>

           
        
    )

}

const ActiveArtworkInfo = ({ artist, title }) => (
    <ArtworkInfo>
        <Artist>
            {artist}
        </Artist>
        <Title>
            {title}
        </Title>
    </ArtworkInfo>
)

const Carousel = () => {
    const initialState = {
        activeTitle: '',
        activeArtist: '',
        artworkList: []
    }

    const prevStep = (event) => {
        event.preventDefault();
        setRotationActive(true)
        setAngle(angle + 60);
        if (isFeatured > 0) {
            setIsFeatured(isFeatured - 1);
        }
        else {
            setIsFeatured(5);
        }
        const timer = setTimeout(() =>{
            setRotationActive(false)
            
            
        }, 600) // triggers after .5 s


    }



    const nextStep = (event) => {
        event.preventDefault();
        setRotationActive(true)
        console.log("next")
        setAngle(angle - 60);
        if (isFeatured < 5) {
            setIsFeatured(isFeatured + 1);
        }
        else {
            setIsFeatured(0);
        }

        const timer = setTimeout(() =>{
            setRotationActive(false)
            
            
        }, 600) // triggers after .5 s

        // triggers this before a new render
        return () => clearTimeout(timer)
        // setState(prev=> ({...prev, activeArtist: prev.artworkList[isFeatured].artistName, activeTitle: prev.artworkList[isFeatured].title}))

    }


    //const { state, loading, error, setSearchTerm } = useHomeFetch();
    //console.log(data.CarouselItems)
    const [state, setState] = useState(initialState);
    const [ itemDetails, showItemDetails ] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [ rotationActive, setRotationActive] = useState(false);
    const [ initialAnimation, setinitialAnimation] = useState(true);
    const [isFeatured, setIsFeatured] = useState(0);
    const [angle, setAngle] = useState(0);
    const initial = useRef(true);
    const animationRef = useRef(null);

    console.log("Is featured: " + isFeatured)
    console.log("data" + data.CarouselItems.length)
    const initializeState = () => {
        for (let i = 0; i < data.CarouselItems.length; i++) {

            setState(prev => ({ ...prev, artworkList: [...prev.artworkList, data.CarouselItems[i]] }))
        }
        console.log("state " + state)
    }

    useEffect(() => {
        console.log("HERE AGAIN")
        initializeState();

    }, [])

    useEffect(() => {
        anime.set('.outerContainer', {
            scale: "0.5"
          });
          anime.set('.cache', {
            scale: "0.5"
          });
          anime.set('.container', {
            scale: "0.5"
          });
          anime.set('.carousel', {
            rotateX: '90'
          });
          anime.set('.artwork', {
            opacity: 0
          });
          anime.set('.artwork', {
            opacity: 0
          });
        const timer = setTimeout(() =>{
            console.log("run animation")
            
            runAnimation()
        }, 3000) // triggers after .5 s

        // triggers this before a new render
        return () => clearTimeout(timer)
    },[])



    const runAnimation = () => {
        setOpacity(1);
    
        var tl = anime.timeline({
            easing: "linear",
            // delay: 3000,
            duration: 4000,
            complete: function () {
                console.log("finished")
                setinitialAnimation(false);
            }
        })
        tl.add({
            targets: ".carousel",
            rotateX: '0',
            duration: 500
        })
        .add({
            targets: ['.Cache', '.Bx'], //outerContainer
            opacity: 0,
            duration: 1000,
          })
          .add({
            targets: ['.outerContainer', '.container'], //outerContainer
            duration: 2000,
            scale: 1,
          });


        animationRef.current = tl
    }

    const handleHover = (event) =>{
        event.preventDefault()
        console.log(" HANDLE HOVER ")

    }

    const { artworkList, activeArtist, activeTitle } = state;

    const values = { artworkList, activeArtist, activeTitle };

    const title = state.artworkList[isFeatured] !== undefined ? state.artworkList[isFeatured].title : '';
    const artist = state.artworkList[isFeatured] !== undefined ? state.artworkList[isFeatured].artistName : '';
    const price = state.artworkList[isFeatured] !== undefined ? state.artworkList[isFeatured].price : '';
    const timer = "33m 50s";

    console.log()
    return (
        <HeroContainer>
            <TitleFirst title={"Cache"} />
            <OuterMostWrapper>
            {
                !initialAnimation && (
                <CarouselButton
                    direction='back'
                    text='back'
                    onClick={prevStep}
                    continueValid={true} />
                     )}
                <OuterWrapper className='outerContainer'>
                <TitleFirst title={"Bx"} />
                    <Container initialAnimation={initialAnimation} className='container'>
                        <CarouselContainer initialAnimation={initialAnimation} className="carousel">
                            {
                                
                                artworkList.map((artwork, idx) => {
                                    
                                    return(
                                    <CarouselItem
                                        opacity={opacity}
                                        className={`artwork`}
                                        key={idx}
                                        index={idx}
                                        angle={angle}
                                        isFeatured={idx === isFeatured ? true : false}
                                        onHover={handleHover}
                                        artwork={artwork}
                                        onMouseEnter={(event) => showItemDetails(true)}
                                        onMouseLeave={(event) => showItemDetails(false)}
                                        />
                                )})
                            }
                            
                        </CarouselContainer>
                    </Container>
                    {
                        !initialAnimation && (
                            <ItemDetails
                                price={price}
                                showItemDetails={showItemDetails}
                                
                               showContent={itemDetails * !rotationActive}
                               remainingTime={timer}
                            ></ItemDetails>
                        )
                    }
                    
                </OuterWrapper>
                {
                !initialAnimation && (
                <CarouselButton
                    direction='next'
                    onClick={nextStep}
                    continueValid={true}
                    
                /> )}

            </OuterMostWrapper>
            {
                !initialAnimation && (
            <ActiveArtworkInfo
                title={title}
                artist={artist}
                
            />
                )
            }
            
        </HeroContainer>
    )
}

export default Carousel
