import styled from "styled-components";

export const Wrapper = styled.div`
width: 37px;
cursor: pointer;
height: 37px;
display: flex;
position: relative;
z-index: 1;
box-shadow: 
    5px 5px 10px 0 rgba(133, 120, 250,.5),
    -5px -5px 10px 0 rgba(255, 255, 255, 1),
    -5px -5px 5px 0 rgba(255, 255, 255, 1);
align-items: center;
justify-content: center;
border-radius: 50%;
background: linear-gradient(45deg,
    #E8E8FF,
     #FFF0FA 25%,
     #FFFBF5 47%,
     #FFFBF5 56%,
     #FFF2FB 65%,
     #F5F5FF 77%
     );
img{
     /* height: 100%;
     width: 23px; */
     position: relative;
     order: ${props => props.direction === 'next' ? 0 : 1};
     transform: ${props => props.direction === 'next' ? 'rotate(0deg)' : 'rotate(180deg)'};
 }
 
 svg{
     /* clip-path: url(#clip-path); */
    width: 100%;
    height: 100%;
    transform: ${props => props.direction === 'next' ? 'rotate(0deg)' : 'rotate(180deg)'};
     
 }
 #applyGradientHere{
        fill: 'url(#linear-gradient)'
     }
 
 `;