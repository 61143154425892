import logo from './logo.svg';
import './App.css';

import Carousel from './components/Carousel'

import { GlobalStyle } from './GlobalStyle';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Carousel></Carousel>
      <GlobalStyle />
    </div>
  );
}

export default App;
