import React, {useRef} from 'react'

import { Wrapper, Image, Video, ItemInfo, Price, PlaceBid, AuctionTimer, Text} from './CarouselItem.styles'

const ImageArtwork = ({artwork, opacity})=>{
    
    return (
        <Image src={artwork} alt="image" opacity={opacity}/>
    )
}

const VideoArtwork = ({artwork, isFeatured, opacity})=>{
    const videoRef = useRef(null);
    if(isFeatured && videoRef.current !== null){
        videoRef.current.play()
    }
    else if(videoRef.current !== null){
        videoRef.current.pause()
    }
    return (
        <Video
        opacity={opacity}
        ref={videoRef}
        src={artwork}
        width="100%"
        autoPlay={false}
        controls={false}
        loop={true}
        // muted
        playsInline
        />
    )
}

const CardInfo = ({title, artist, price}) =>{
    return (
        <ItemInfo>
            <Price>
                <div>{price}</div>
            <Text>Current Bid</Text>
            </Price>
            <PlaceBid>

            </PlaceBid>
            <AuctionTimer>

            </AuctionTimer>
        
        </ItemInfo>
    )
}


const CarouselItem = ({index, artwork, current, isFeatured, angle, opacity, className, onMouseEnter, onMouseLeave}) => {
    
    return (
        <Wrapper className={className} index={index} isCurrent={isFeatured} angle={angle} >
            {
                artwork.type === 'image' && (
                    <ImageArtwork opacity={opacity} artwork={artwork.url}></ImageArtwork>
                )
                
            }
            {
                artwork.type === 'video' && (
                    <VideoArtwork opacity={opacity} isFeatured={isFeatured} artwork={artwork.url}></VideoArtwork>
                )
            }
            {/* <CardInfo
                artist={artwork.artistName}
                title={artwork.title}
                price={artwork.price}
            ></CardInfo> */}
        </Wrapper>
    )
}

export default CarouselItem
