import React from 'react'

import { Wrapper } from './CarouselButton.styles'

import NextArrow from '../../images/formNavArrow.svg'
import NeuArrow from '../../images/neumorphicArrow.svg'


const Arrow = () => (
    <>
    

<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 37 37">
    <defs>
    <filter id="shadow" width="200%" height="200%" x="-50%" y="-50%" >
    <feDropShadow dx="0" dy="0" stdDeviation="3.5"
          floodColor="rgba(133, 120, 250,1)"/>
    </filter>
    <linearGradient id="linear-gradient" x1="175.62" y1="-129.77" x2="179.82" y2="-129.77" gradientTransform="translate(236.86 55.74) rotate(-135)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#f2f2ff"/>
            <stop offset="0.5" stopColor="#fff"/>
            <stop offset="1" stopColor="#f2f2ff"/>
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="182.43" y1="-122.96" x2="182.43" y2="-127.16" gradientTransform="translate(-11.48 24.9) rotate(-135)"  gradientUnits="userSpaceOnUse">
              <stop offset="0" stopColor="#f2f2ff"/>
            <stop offset="0.5" stopColor="#fff"/>
            <stop offset="1" stopColor="#f2f2ff"/>
        </linearGradient>
        </defs>
        <circle cx="18.5" cy="18.5" r="18.5" fill="transparent"/>
    <g filter="url(#shadow)" id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path fill="#fff"d="M24.25,17,18,10.79a2.1,2.1,0,0,0-3,3L19.8,18.5l-4.75,4.75a2.1,2.1,0,0,0,3,3L24.25,20a2.13,2.13,0,0,0,.61-1.49A2.11,2.11,0,0,0,24.25,17Z" />
            <path fill="url(#linear-gradient)" d="M15.05,23.25,19.8,18.5h5.06A2.13,2.13,0,0,1,24.25,20L18,26.21a2.1,2.1,0,0,1-3-3Z"/>
            <path fill="url(#linear-gradient-2)" d="M15.06,10.79a2.09,2.09,0,0,1,3,0L24.25,17a2.11,2.11,0,0,1,.61,1.48H19.8l-4.75-4.74A2.09,2.09,0,0,1,15.06,10.79Z"/>
        </g>
    </g>
        </svg>




</>
)


const CarouselButton = ({ direction, onClick, continueValid, text }) => {
    return (
        <Wrapper direction={direction} onClick={onClick}

            validContinue={continueValid}>
            <Arrow></Arrow>
            {/* <img src={NeuArrow} alt="arrow" /> */}
        </Wrapper>
    )
}

export default CarouselButton
